.main-content {
  min-height: 75vh;
  flex-grow: 1;
  background-color: var(--settings-body-background);
  color: var(--settings-body-text-color);
}

.padding-top {
  padding-top: 30px;
}

.logoImage {
  width: 90px;
  height: 90px;
  margin: 0px 20px 10px;
}
.video-button {
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  text-transform: uppercase !important;
  min-width: 56px !important;
}
.flip-countdown.size-medium .flip-countdown-piece .flip-countdown-title {
  font-size: 18px !important;
  margin-top: 16px !important;
  font-weight: 600;
}
.flip-countdown .flip-countdown-piece .flip-countdown-card {
  margin-bottom: 10px;
}
@media (max-width: 820px) {
  .video-button {
    font-size: 14px !important;
    padding: 4px !important;
    min-width: 0px !important;
  }
}
