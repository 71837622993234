/* BitmovinPlayerWithTicker.css */

.player-container {
  position: relative;
}

.ticker-overlay {
  bottom: 300;
  position: absolute;
  z-index: 100;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 10px;
  white-space: nowrap;
  overflow: hidden;
}

.ticker-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  position: relative;
}
.ticker-message {
  display: inline-block;
  padding-left: 100%; /* Start from the right edge */
  animation: ticker-scroll 18s linear infinite;
  width: max-content; /* Ensure the message doesn't wrap to the next line */
  box-sizing: border-box;
}
@keyframes ticker-scroll {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
.marquee {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
}

.marquee span {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 10s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
