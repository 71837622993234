@import "~rsuite/dist/rsuite.min.css";
body {
  margin: 0; /* Remove any default margins */
  padding: 0; /* Remove any default padding */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "SF UI Text";
  src: url("../public/fonts/SFUIText-Bold.eot");
  src: url("../public/fonts/SFUIText-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/SFUIText-Bold.woff2") format("woff2"),
    url("../public/fonts/SFUIText-Bold.woff") format("woff"),
    url("../public/fonts/SFUIText-Bold.ttf") format("truetype"),
    url("../public/fonts/SFUIText-Bold.svg#SFUIText-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF UI Text";
  src: url("../public/fonts/SFUIText-Medium.eot");
  src: url("../public/fonts/SFUIText-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/SFUIText-Medium.woff2") format("woff2"),
    url("../public/fonts/SFUIText-Medium.woff") format("woff"),
    url("../public/fonts/SFUIText-Medium.ttf") format("truetype"),
    url("../public/fonts/SFUIText-Medium.svg#SFUIText-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF UI Text";
  src: url("../public/fonts/SFUIText-Semibold.eot");
  src: url("../public/fonts/SFUIText-Semibold.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/SFUIText-Semibold.woff2") format("woff2"),
    url("../public/fonts/SFUIText-Semibold.woff") format("woff"),
    url("../public/fonts/SFUIText-Semibold.ttf") format("truetype"),
    url("../public/fonts/SFUIText-Semibold.svg#SFUIText-Semibold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF UI Text";
  src: url("../public/fonts/SFUIText-Regular.eot");
  src: url("../public/fonts/SFUIText-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/SFUIText-Regular.woff2") format("woff2"),
    url("../public/fonts/SFUIText-Regular.woff") format("woff"),
    url("../public/fonts/SFUIText-Regular.ttf") format("truetype"),
    url("../public/fonts/SFUIText-Regular.svg#SFUIText-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* W3.CSS 4.15 December 2020 by Jan Egil and Borge Refsnes */
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
/* Extract from normalize.css by Nicolas Gallagher and Jonathan Neal git.io/normalize */
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section {
  display: block;
}
summary {
  display: list-item;
}
audio,
canvas,
progress,
video {
  display: inline-block;
}
progress {
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline-width: 0;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: bolder;
}
dfn {
  font-style: italic;
}
mark {
  background: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
figure {
  margin: 1em 40px;
}
img {
  border-style: none;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
button,
input,
select,
textarea,
optgroup {
  font: inherit;
  margin: 0;
}
optgroup {
  font-weight: bold;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
[type="button"],
[type="reset"],
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
/* End extract */
html,
body {
  font-family: Verdana, sans-serif;
  font-size: 15px;
  line-height: 1.5;
}
html {
  overflow-x: hidden;
}
h1 {
  font-size: 36px;
}
h2 {
  font-size: 30px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 16px;
}
.w3-serif {
  font-family: serif;
}
.w3-sans-serif {
  font-family: sans-serif;
}
.w3-cursive {
  font-family: cursive;
}
.w3-monospace {
  font-family: monospace;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Segoe UI", Arial, sans-serif;
  font-weight: 400;
  margin: 10px 0;
}
.w3-wide {
  letter-spacing: 4px;
}
hr {
  border: 0;
  border-top: 1px solid #eee;
  margin: 20px 0;
}
.w3-image {
  max-width: 100%;
  height: auto;
}
img {
  vertical-align: middle;
}
a {
  color: inherit;
}
.w3-table,
.w3-table-all {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  display: table;
}
.w3-table-all {
  border: 1px solid #ccc;
}
.w3-bordered tr,
.w3-table-all tr {
  border-bottom: 1px solid #ddd;
}
.w3-striped tbody tr:nth-child(even) {
  background-color: #f1f1f1;
}
.w3-table-all tr:nth-child(odd) {
  background-color: #fff;
}
.w3-table-all tr:nth-child(even) {
  background-color: #f1f1f1;
}
.w3-hoverable tbody tr:hover,
.w3-ul.w3-hoverable li:hover {
  background-color: #ccc;
}
.w3-centered tr th,
.w3-centered tr td {
  text-align: center;
}
.w3-table td,
.w3-table th,
.w3-table-all td,
.w3-table-all th {
  padding: 8px 8px;
  display: table-cell;
  text-align: left;
  vertical-align: top;
}
.w3-table th:first-child,
.w3-table td:first-child,
.w3-table-all th:first-child,
.w3-table-all td:first-child {
  padding-left: 16px;
}
.w3-btn,
.w3-button {
  border: none;
  display: inline-block;
  padding: 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
}
.w3-btn:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.w3-btn,
.w3-button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.w3-disabled,
.w3-btn:disabled,
.w3-button:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}
.w3-disabled *,
:disabled * {
  pointer-events: none;
}
.w3-btn.w3-disabled:hover,
.w3-btn:disabled:hover {
  box-shadow: none;
}
.w3-badge,
.w3-tag {
  background-color: #000;
  color: #fff;
  display: inline-block;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
}
.w3-badge {
  border-radius: 50%;
}
.w3-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.w3-ul li {
  padding: 8px 16px;
  border-bottom: 1px solid #ddd;
}
.w3-ul li:last-child {
  border-bottom: none;
}
.w3-tooltip,
.w3-display-container {
  position: relative;
}
.w3-tooltip .w3-text {
  display: none;
}
.w3-tooltip:hover .w3-text {
  display: inline-block;
}
.w3-ripple:active {
  opacity: 0.5;
}
.w3-ripple {
  transition: opacity 0s;
}
.w3-input {
  padding: 8px;
  display: block;
  border: none;
  border-bottom: 1px solid #ccc;
  width: 100%;
}
.w3-select {
  padding: 9px 0;
  width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
}
.w3-dropdown-click,
.w3-dropdown-hover {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.w3-dropdown-hover:hover .w3-dropdown-content {
  display: block;
}
.w3-dropdown-hover:first-child,
.w3-dropdown-click:hover {
  background-color: #ccc;
  color: #000;
}
.w3-dropdown-hover:hover > .w3-button:first-child,
.w3-dropdown-click:hover > .w3-button:first-child {
  background-color: #ccc;
  color: #000;
}
.w3-dropdown-content {
  cursor: auto;
  color: #000;
  background-color: #fff;
  display: none;
  position: absolute;
  min-width: 160px;
  margin: 0;
  padding: 0;
  z-index: 1;
}
.w3-check,
.w3-radio {
  width: 24px;
  height: 24px;
  position: relative;
  top: 6px;
}
.w3-sidebar {
  height: 100%;
  width: 200px;
  background-color: #fff;
  position: fixed !important;
  z-index: 1;
  overflow: auto;
}
.w3-bar-block .w3-dropdown-hover,
.w3-bar-block .w3-dropdown-click {
  width: 100%;
}
.w3-bar-block .w3-dropdown-hover .w3-dropdown-content,
.w3-bar-block .w3-dropdown-click .w3-dropdown-content {
  min-width: 100%;
}
.w3-bar-block .w3-dropdown-hover .w3-button,
.w3-bar-block .w3-dropdown-click .w3-button {
  width: 100%;
  text-align: left;
  padding: 8px 16px;
}
.w3-main,
#main {
  transition: margin-left 0.4s;
}
.w3-modal {
  z-index: 3;
  display: none;
  padding-top: 100px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}
.w3-modal-content {
  margin: auto;
  background-color: #fff;
  position: relative;
  padding: 0;
  outline: 0;
  width: 600px;
}
.w3-bar {
  width: 100%;
  overflow: hidden;
}
.w3-center .w3-bar {
  display: inline-block;
  width: auto;
}
.w3-bar .w3-bar-item {
  padding: 8px 16px;
  float: left;
  width: auto;
  border: none;
  display: block;
  outline: 0;
}
.w3-bar .w3-dropdown-hover,
.w3-bar .w3-dropdown-click {
  position: static;
  float: left;
}
.w3-bar .w3-button {
  white-space: normal;
}
.w3-bar-block .w3-bar-item {
  width: 100%;
  display: block;
  padding: 8px 16px;
  text-align: left;
  border: none;
  white-space: normal;
  float: none;
  outline: 0;
}
.w3-bar-block.w3-center .w3-bar-item {
  text-align: center;
}
.w3-block {
  display: block;
  width: 100%;
}
.w3-responsive {
  display: block;
  overflow-x: auto;
}
.w3-container:after,
.w3-container:before,
.w3-panel:after,
.w3-panel:before,
.w3-row:after,
.w3-row:before,
.w3-row-padding:after,
.w3-row-padding:before,
.w3-cell-row:before,
.w3-cell-row:after,
.w3-clear:after,
.w3-clear:before,
.w3-bar:before,
.w3-bar:after {
  content: "";
  display: table;
  clear: both;
}
.w3-col,
.w3-half,
.w3-third,
.w3-twothird,
.w3-threequarter,
.w3-quarter {
  float: left;
  width: 100%;
}
.w3-col.s1 {
  width: 8.33333%;
}
.w3-col.s2 {
  width: 16.66666%;
}
.w3-col.s3 {
  width: 24.99999%;
}
.w3-col.s4 {
  width: 33.33333%;
}
.w3-col.s5 {
  width: 41.66666%;
}
.w3-col.s6 {
  width: 49.99999%;
}
.w3-col.s7 {
  width: 58.33333%;
}
.w3-col.s8 {
  width: 66.66666%;
}
.w3-col.s9 {
  width: 74.99999%;
}
.w3-col.s10 {
  width: 83.33333%;
}
.w3-col.s11 {
  width: 91.66666%;
}
.w3-col.s12 {
  width: 99.99999%;
}
@media (min-width: 601px) {
  .w3-col.m1 {
    width: 8.33333%;
  }
  .w3-col.m2 {
    width: 16.66666%;
  }
  .w3-col.m3,
  .w3-quarter {
    width: 24.99999%;
  }
  .w3-col.m4,
  .w3-third {
    width: 33.33333%;
  }
  .w3-col.m5 {
    width: 41.66666%;
  }
  .w3-col.m6,
  .w3-half {
    width: 49.99999%;
  }
  .w3-col.m7 {
    width: 58.33333%;
  }
  .w3-col.m8,
  .w3-twothird {
    width: 66.66666%;
  }
  .w3-col.m9,
  .w3-threequarter {
    width: 74.99999%;
  }
  .w3-col.m10 {
    width: 83.33333%;
  }
  .w3-col.m11 {
    width: 91.66666%;
  }
  .w3-col.m12 {
    width: 99.99999%;
  }
}
@media (min-width: 993px) {
  .w3-col.l1 {
    width: 8.33333%;
  }
  .w3-col.l2 {
    width: 16.66666%;
  }
  .w3-col.l3 {
    width: 24.99999%;
  }
  .w3-col.l4 {
    width: 33.33333%;
  }
  .w3-col.l5 {
    width: 41.66666%;
  }
  .w3-col.l6 {
    width: 49.99999%;
  }
  .w3-col.l7 {
    width: 58.33333%;
  }

  .w3-col.l8 {
    width: 66.66666%;
  }
  .w3-col.l9 {
    width: 74.99999%;
  }
  .w3-col.l10 {
    width: 83.33333%;
  }
  .w3-col.l11 {
    width: 91.66666%;
  }
  .w3-col.l12 {
    width: 99.99999%;
  }
}
.w3-rest {
  overflow: hidden;
}
.w3-stretch {
  margin-left: -16px;
  margin-right: -16px;
}
.w3-content,
.w3-auto {
  margin-left: auto;
  margin-right: auto;
}
.w3-content {
  max-width: 980px;
}
.w3-auto {
  max-width: 1140px;
}
.w3-cell-row {
  display: table;
  width: 100%;
}
.w3-cell {
  display: table-cell;
}
.w3-cell-top {
  vertical-align: top;
}
.w3-cell-middle {
  vertical-align: middle;
}
.w3-cell-bottom {
  vertical-align: bottom;
}
.w3-hide {
  display: none !important;
}
.w3-show-block,
.w3-show {
  display: block !important;
}
.w3-show-inline-block {
  display: inline-block !important;
}
@media (max-width: 1205px) {
  .w3-auto {
    max-width: 95%;
  }
}
@media (max-width: 600px) {
  .w3-modal-content {
    margin: 0 10px;
    width: auto !important;
  }
  .w3-modal {
    padding-top: 30px;
  }
  .w3-dropdown-hover.w3-mobile .w3-dropdown-content,
  .w3-dropdown-click.w3-mobile .w3-dropdown-content {
    position: relative;
  }
  .w3-hide-small {
    display: none !important;
  }
  .w3-mobile {
    display: block;
    width: 100% !important;
  }
  .w3-bar-item.w3-mobile,
  .w3-dropdown-hover.w3-mobile,
  .w3-dropdown-click.w3-mobile {
    text-align: center;
  }
  .w3-dropdown-hover.w3-mobile,
  .w3-dropdown-hover.w3-mobile .w3-btn,
  .w3-dropdown-hover.w3-mobile .w3-button,
  .w3-dropdown-click.w3-mobile,
  .w3-dropdown-click.w3-mobile .w3-btn,
  .w3-dropdown-click.w3-mobile .w3-button {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .w3-modal-content {
    width: 500px;
  }
  .w3-modal {
    padding-top: 50px;
  }
}
@media (min-width: 993px) {
  .w3-modal-content {
    width: 900px;
  }
  .w3-hide-large {
    display: none !important;
  }
  .w3-sidebar.w3-collapse {
    display: block !important;
  }
}
@media (max-width: 992px) and (min-width: 601px) {
  .w3-hide-medium {
    display: none !important;
  }
}
@media (max-width: 992px) {
  .w3-sidebar.w3-collapse {
    display: none;
  }
  .w3-main {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .w3-auto {
    max-width: 100%;
  }
}
.w3-top,
.w3-bottom {
  position: fixed;
  width: 100%;
  z-index: 1;
}
.w3-top {
  top: 0;
}
.w3-bottom {
  bottom: 0;
}
.w3-overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
.w3-display-topleft {
  position: absolute;
  left: 0;
  top: 0;
}
.w3-display-topright {
  position: absolute;
  right: 0;
  top: 0;
}
.w3-display-bottomleft {
  position: absolute;
  left: 0;
  bottom: 0;
}
.w3-display-bottomright {
  position: absolute;
  right: 0;
  bottom: 0;
}
.w3-display-middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.w3-display-left {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%);
  -ms-transform: translate(-0%, -50%);
}
.w3-display-right {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
}
.w3-display-topmiddle {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
}
.w3-display-bottommiddle {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
}
.w3-display-container:hover .w3-display-hover {
  display: block;
}
.w3-display-container:hover span.w3-display-hover {
  display: inline-block;
}
.w3-display-hover {
  display: none;
}
.w3-display-position {
  position: absolute;
}
.w3-circle {
  border-radius: 50%;
}
.w3-round-small {
  border-radius: 2px;
}
.w3-round,
.w3-round-medium {
  border-radius: 4px;
}
.w3-round-large {
  border-radius: 8px;
}
.w3-round-xlarge {
  border-radius: 16px;
}
.w3-round-xxlarge {
  border-radius: 32px;
}
.w3-row-padding,
.w3-row-padding > .w3-half,
.w3-row-padding > .w3-third,
.w3-row-padding > .w3-twothird,
.w3-row-padding > .w3-threequarter,
.w3-row-padding > .w3-quarter,
.w3-row-padding > .w3-col {
  padding: 0 8px;
}
.w3-container,
.w3-panel {
  padding: 0.01em 16px;
}
.w3-panel {
  margin-top: 16px;
  margin-bottom: 16px;
}
.w3-code,
.w3-codespan {
  font-family: Consolas, "courier new";
  font-size: 16px;
}
.w3-code {
  width: auto;
  background-color: #fff;
  padding: 8px 12px;
  border-left: 4px solid #4caf50;
  word-wrap: break-word;
}
.w3-codespan {
  color: crimson;
  background-color: #f1f1f1;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 110%;
}
.w3-card,
.w3-card-2 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.w3-card-4,
.w3-hover-shadow:hover {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19);
}
.w3-spin {
  animation: w3-spin 2s infinite linear;
}
@keyframes w3-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.w3-animate-fading {
  animation: fading 10s infinite;
}
@keyframes fading {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.w3-animate-opacity {
  animation: opac 0.8s;
}
@keyframes opac {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.w3-animate-top {
  position: relative;
  animation: animatetop 0.4s;
}
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
.w3-animate-left {
  position: relative;
  animation: animateleft 0.4s;
}
@keyframes animateleft {
  from {
    left: -300px;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}
.w3-animate-right {
  position: relative;
  animation: animateright 0.4s;
}
@keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}
.w3-animate-bottom {
  position: relative;
  animation: animatebottom 0.4s;
}
@keyframes animatebottom {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}
.w3-animate-zoom {
  animation: animatezoom 0.6s;
}
@keyframes animatezoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.w3-animate-input {
  transition: width 0.4s ease-in-out;
}
.w3-animate-input:focus {
  width: 100% !important;
}
.w3-opacity,
.w3-hover-opacity:hover {
  opacity: 0.6;
}
.w3-opacity-off,
.w3-hover-opacity-off:hover {
  opacity: 1;
}
.w3-opacity-max {
  opacity: 0.25;
}
.w3-opacity-min {
  opacity: 0.75;
}
.w3-greyscale-max,
.w3-grayscale-max,
.w3-hover-greyscale:hover,
.w3-hover-grayscale:hover {
  filter: grayscale(100%);
}
.w3-greyscale,
.w3-grayscale {
  filter: grayscale(75%);
}
.w3-greyscale-min,
.w3-grayscale-min {
  filter: grayscale(50%);
}
.w3-sepia {
  filter: sepia(75%);
}
.w3-sepia-max,
.w3-hover-sepia:hover {
  filter: sepia(100%);
}
.w3-sepia-min {
  filter: sepia(50%);
}
.w3-tiny {
  font-size: 10px !important;
}
.w3-small {
  font-size: 12px !important;
}
.w3-medium {
  font-size: 15px !important;
}
.w3-large {
  font-size: 18px !important;
}
.w3-xlarge {
  font-size: 24px !important;
}
.w3-xxlarge {
  font-size: 36px !important;
}
.w3-xxxlarge {
  font-size: 48px !important;
}
.w3-jumbo {
  font-size: 64px !important;
}
.w3-left-align {
  text-align: left !important;
}
.w3-right-align {
  text-align: right !important;
}
.w3-justify {
  text-align: justify !important;
}
.w3-center {
  text-align: center !important;
}
.w3-border-0 {
  border: 0 !important;
}
.w3-border {
  border: 1px solid #ccc !important;
}
.w3-border-top {
  border-top: 1px solid #ccc !important;
}
.w3-border-bottom {
  border-bottom: 1px solid #ccc !important;
}
.w3-border-left {
  border-left: 1px solid #ccc !important;
}
.w3-border-right {
  border-right: 1px solid #ccc !important;
}
.w3-topbar {
  border-top: 6px solid #ccc !important;
}
.w3-bottombar {
  border-bottom: 6px solid #ccc !important;
}
.w3-leftbar {
  border-left: 6px solid #ccc !important;
}
.w3-rightbar {
  border-right: 6px solid #ccc !important;
}
.w3-section,
.w3-code {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
.w3-margin {
  margin: 16px !important;
}
.w3-margin-top {
  margin-top: 16px !important;
}
.w3-margin-bottom {
  margin-bottom: 16px !important;
}
.w3-margin-left {
  margin-left: 16px !important;
}
.w3-margin-right {
  margin-right: 16px !important;
}
.w3-padding-small {
  padding: 4px 8px !important;
}
.w3-padding {
  padding: 8px 16px !important;
}
.w3-padding-large {
  padding: 12px 24px !important;
}
.w3-padding-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.w3-padding-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}
.w3-padding-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}
.w3-padding-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}
.w3-padding-64 {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}
.w3-padding-top-64 {
  padding-top: 64px !important;
}
.w3-padding-top-48 {
  padding-top: 48px !important;
}
.w3-padding-top-32 {
  padding-top: 32px !important;
}
.w3-padding-top-24 {
  padding-top: 24px !important;
}
.w3-left {
  float: left !important;
}
.w3-right {
  float: right !important;
}
.w3-button:hover {
  color: #000 !important;
  background-color: #ccc !important;
}
.w3-transparent,
.w3-hover-none:hover {
  background-color: transparent !important;
}
.w3-hover-none:hover {
  box-shadow: none !important;
}
/* Colors */
.w3-amber,
.w3-hover-amber:hover {
  color: #000 !important;
  background-color: #ffc107 !important;
}
.w3-aqua,
.w3-hover-aqua:hover {
  color: #000 !important;
  background-color: #00ffff !important;
}
.w3-blue,
.w3-hover-blue:hover {
  color: #fff !important;
  background-color: #2196f3 !important;
}
.w3-light-blue,
.w3-hover-light-blue:hover {
  color: #000 !important;
  background-color: #87ceeb !important;
}
.w3-brown,
.w3-hover-brown:hover {
  color: #fff !important;
  background-color: #795548 !important;
}
.w3-cyan,
.w3-hover-cyan:hover {
  color: #000 !important;
  background-color: #00bcd4 !important;
}
.w3-blue-grey,
.w3-hover-blue-grey:hover,
.w3-blue-gray,
.w3-hover-blue-gray:hover {
  color: #fff !important;
  background-color: #607d8b !important;
}
.w3-green,
.w3-hover-green:hover {
  color: #fff !important;
  background-color: #4caf50 !important;
}
.w3-light-green,
.w3-hover-light-green:hover {
  color: #000 !important;
  background-color: #8bc34a !important;
}
.w3-indigo,
.w3-hover-indigo:hover {
  color: #fff !important;
  background-color: #3f51b5 !important;
}
.w3-khaki,
.w3-hover-khaki:hover {
  color: #000 !important;
  background-color: #f0e68c !important;
}
.w3-lime,
.w3-hover-lime:hover {
  color: #000 !important;
  background-color: #cddc39 !important;
}
.w3-orange,
.w3-hover-orange:hover {
  color: #000 !important;
  background-color: #ff9800 !important;
}
.w3-deep-orange,
.w3-hover-deep-orange:hover {
  color: #fff !important;
  background-color: #ff5722 !important;
}
.w3-pink,
.w3-hover-pink:hover {
  color: #fff !important;
  background-color: #e91e63 !important;
}
.w3-purple,
.w3-hover-purple:hover {
  color: #fff !important;
  background-color: #9c27b0 !important;
}
.w3-deep-purple,
.w3-hover-deep-purple:hover {
  color: #fff !important;
  background-color: #673ab7 !important;
}
.w3-red,
.w3-hover-red:hover {
  color: #fff !important;
  background-color: #f44336 !important;
}
.w3-sand,
.w3-hover-sand:hover {
  color: #000 !important;
  background-color: #fdf5e6 !important;
}
.w3-teal,
.w3-hover-teal:hover {
  color: #fff !important;
  background-color: #009688 !important;
}
.w3-yellow,
.w3-hover-yellow:hover {
  color: #000 !important;
  background-color: #ffeb3b !important;
}
.w3-white,
.w3-hover-white:hover {
  color: #000 !important;
  background-color: #fff !important;
}
.w3-black,
.w3-hover-black:hover {
  color: #fff !important;
  background-color: #000 !important;
}
.w3-grey,
.w3-hover-grey:hover,
.w3-gray,
.w3-hover-gray:hover {
  color: #000 !important;
  background-color: #9e9e9e !important;
}
.w3-light-grey,
.w3-hover-light-grey:hover,
.w3-light-gray,
.w3-hover-light-gray:hover {
  color: #000 !important;
  background-color: #f1f1f1 !important;
}
.w3-dark-grey,
.w3-hover-dark-grey:hover,
.w3-dark-gray,
.w3-hover-dark-gray:hover {
  color: #fff !important;
  background-color: #616161 !important;
}
.w3-pale-red,
.w3-hover-pale-red:hover {
  color: #000 !important;
  background-color: #ffdddd !important;
}
.w3-pale-green,
.w3-hover-pale-green:hover {
  color: #000 !important;
  background-color: #ddffdd !important;
}
.w3-pale-yellow,
.w3-hover-pale-yellow:hover {
  color: #000 !important;
  background-color: #ffffcc !important;
}
.w3-pale-blue,
.w3-hover-pale-blue:hover {
  color: #000 !important;
  background-color: #ddffff !important;
}
.w3-text-amber,
.w3-hover-text-amber:hover {
  color: #ffc107 !important;
}
.w3-text-aqua,
.w3-hover-text-aqua:hover {
  color: #00ffff !important;
}
.w3-text-blue,
.w3-hover-text-blue:hover {
  color: #2196f3 !important;
}
.w3-text-light-blue,
.w3-hover-text-light-blue:hover {
  color: #87ceeb !important;
}
.w3-text-brown,
.w3-hover-text-brown:hover {
  color: #795548 !important;
}
.w3-text-cyan,
.w3-hover-text-cyan:hover {
  color: #00bcd4 !important;
}
.w3-text-blue-grey,
.w3-hover-text-blue-grey:hover,
.w3-text-blue-gray,
.w3-hover-text-blue-gray:hover {
  color: #607d8b !important;
}
.w3-text-green,
.w3-hover-text-green:hover {
  color: #4caf50 !important;
}
.w3-text-light-green,
.w3-hover-text-light-green:hover {
  color: #8bc34a !important;
}
.w3-text-indigo,
.w3-hover-text-indigo:hover {
  color: #3f51b5 !important;
}
.w3-text-khaki,
.w3-hover-text-khaki:hover {
  color: #b4aa50 !important;
}
.w3-text-lime,
.w3-hover-text-lime:hover {
  color: #cddc39 !important;
}
.w3-text-orange,
.w3-hover-text-orange:hover {
  color: #ff9800 !important;
}
.w3-text-deep-orange,
.w3-hover-text-deep-orange:hover {
  color: #ff5722 !important;
}
.w3-text-pink,
.w3-hover-text-pink:hover {
  color: #e91e63 !important;
}
.w3-text-purple,
.w3-hover-text-purple:hover {
  color: #9c27b0 !important;
}
.w3-text-deep-purple,
.w3-hover-text-deep-purple:hover {
  color: #673ab7 !important;
}
.w3-text-red,
.w3-hover-text-red:hover {
  color: #f44336 !important;
}
.w3-text-sand,
.w3-hover-text-sand:hover {
  color: #fdf5e6 !important;
}
.w3-text-teal,
.w3-hover-text-teal:hover {
  color: #009688 !important;
}
.w3-text-yellow,
.w3-hover-text-yellow:hover {
  color: #d2be0e !important;
}
.w3-text-white,
.w3-hover-text-white:hover {
  color: #fff !important;
}
.w3-text-black,
.w3-hover-text-black:hover {
  color: #000 !important;
}
.w3-text-grey,
.w3-hover-text-grey:hover,
.w3-text-gray,
.w3-hover-text-gray:hover {
  color: #757575 !important;
}
.w3-text-light-grey,
.w3-hover-text-light-grey:hover,
.w3-text-light-gray,
.w3-hover-text-light-gray:hover {
  color: #f1f1f1 !important;
}
.w3-text-dark-grey,
.w3-hover-text-dark-grey:hover,
.w3-text-dark-gray,
.w3-hover-text-dark-gray:hover {
  color: #3a3a3a !important;
}
.w3-border-amber,
.w3-hover-border-amber:hover {
  border-color: #ffc107 !important;
}
.w3-border-aqua,
.w3-hover-border-aqua:hover {
  border-color: #00ffff !important;
}
.w3-border-blue,
.w3-hover-border-blue:hover {
  border-color: #2196f3 !important;
}
.w3-border-light-blue,
.w3-hover-border-light-blue:hover {
  border-color: #87ceeb !important;
}
.w3-border-brown,
.w3-hover-border-brown:hover {
  border-color: #795548 !important;
}
.w3-border-cyan,
.w3-hover-border-cyan:hover {
  border-color: #00bcd4 !important;
}
.w3-border-blue-grey,
.w3-hover-border-blue-grey:hover,
.w3-border-blue-gray,
.w3-hover-border-blue-gray:hover {
  border-color: #607d8b !important;
}
.w3-border-green,
.w3-hover-border-green:hover {
  border-color: #4caf50 !important;
}
.w3-border-light-green,
.w3-hover-border-light-green:hover {
  border-color: #8bc34a !important;
}
.w3-border-indigo,
.w3-hover-border-indigo:hover {
  border-color: #3f51b5 !important;
}
.w3-border-khaki,
.w3-hover-border-khaki:hover {
  border-color: #f0e68c !important;
}
.w3-border-lime,
.w3-hover-border-lime:hover {
  border-color: #cddc39 !important;
}
.w3-border-orange,
.w3-hover-border-orange:hover {
  border-color: #ff9800 !important;
}
.w3-border-deep-orange,
.w3-hover-border-deep-orange:hover {
  border-color: #ff5722 !important;
}
.w3-border-pink,
.w3-hover-border-pink:hover {
  border-color: #e91e63 !important;
}
.w3-border-purple,
.w3-hover-border-purple:hover {
  border-color: #9c27b0 !important;
}
.w3-border-deep-purple,
.w3-hover-border-deep-purple:hover {
  border-color: #673ab7 !important;
}
.w3-border-red,
.w3-hover-border-red:hover {
  border-color: #f44336 !important;
}
.w3-border-sand,
.w3-hover-border-sand:hover {
  border-color: #fdf5e6 !important;
}
.w3-border-teal,
.w3-hover-border-teal:hover {
  border-color: #009688 !important;
}
.w3-border-yellow,
.w3-hover-border-yellow:hover {
  border-color: #ffeb3b !important;
}
.w3-border-white,
.w3-hover-border-white:hover {
  border-color: #fff !important;
}
.w3-border-black,
.w3-hover-border-black:hover {
  border-color: #000 !important;
}
.w3-border-grey,
.w3-hover-border-grey:hover,
.w3-border-gray,
.w3-hover-border-gray:hover {
  border-color: #9e9e9e !important;
}
.w3-border-light-grey,
.w3-hover-border-light-grey:hover,
.w3-border-light-gray,
.w3-hover-border-light-gray:hover {
  border-color: #f1f1f1 !important;
}
.w3-border-dark-grey,
.w3-hover-border-dark-grey:hover,
.w3-border-dark-gray,
.w3-hover-border-dark-gray:hover {
  border-color: #616161 !important;
}
.w3-border-pale-red,
.w3-hover-border-pale-red:hover {
  border-color: #ffe7e7 !important;
}
.w3-border-pale-green,
.w3-hover-border-pale-green:hover {
  border-color: #e7ffe7 !important;
}
.w3-border-pale-yellow,
.w3-hover-border-pale-yellow:hover {
  border-color: #ffffcc !important;
}
.w3-border-pale-blue,
.w3-hover-border-pale-blue:hover {
  border-color: #e7ffff !important;
}

body {
  --settings-header-background: #edf2f7;
  --settings-header-text-color: #6d7278;
  --settings-header-hover-color: #003876;
  --settings-header-font-family: Roboto;
  --settings-footer-background: #003876;
  --settings-footer-text-color: #ffffff;
  --settings-footer-hover-color: #686868;
  --settings-footer-font-family: Roboto;
  --settings-body-background: #edf2f7;
  --settings-body-text-color: #282b2e;
  --settings-body-main-color: #dce2e9;
  --settings-body-font-family: Roboto;
  --settings-button-background: #be311a;
  --settings-button-text-color: #ffffff;
  --settings-slider-button-background: #be311a;
  --settings-slider-button-text-color: #ffffff;
  --settings-common-button-background: #00387a;
  --settings-watch-button-background: #00387a;
  --settings-register-watch-button-background: #00387a;
  --settings-common-button-text-color: #ffffff;
  --settings-watch-button-text-color: #ffffff;
  --settings-register-watch-button-text-color: #ffffff;
  --settings-buy-current-event-button-background: #251abc;
  --settings-watch-current-event-button-background: #251abc;
  --settings-registration-current-event-button-background: #00387a;
  --settings-buy-current-event-button-text-color: #ffffff;
  --settings-watch-current-event-button-text-color: #ffffff;
  --settings-registration-current-event-button-text-color: #ffffff;
  --settings-login-button-background: #191919;
  --settings-login-button-text-color: #ffffff;
  --settings-registration-button-background: #191919;
  --settings-registration-button-text-color: #ffffff;
  --settings-package-background: #00387a;
  --settings-package-text-color: #ffffff;

  --grey-100: #f9f9fa;
  --grey-200: #cfd7e3;
  --grey-300: #b5c0cd;
  --grey-800: #3e4e63;
  --black: #000;
  --theme-main-background: #191919;
  --theme-secondary-background: #eef2f5;
  --theme-tertiary-background: #f6f6f7;
  --theme-tertiary-background-rgb: 246, 246, 247;
  --button-primary-background: #000;
  --button-primary-background-rgb: 0, 0, 0;
  --text-primary: #000;
  --text-primary-rgb: 0, 0, 0;
  --text-secondary: #fff;
  --text-secondary-rgb: 255, 255, 255;
  --text-tertiary-dark: #555;
  --text-tertiary-dark-rgb: 85, 85, 85;
  --text-tertiary-middle: #959799;
  --text-tertiary-middle-rbg: 149, 151, 153;
  --text-tertiary-light: #e9ecef;
  --text-tertiary-light-rgb: 233, 236, 239;
  --theme-link-color: #000;
  --theme-link-accent: #d11d1d;
  --theme-box-shadow: 0px 0px 0px 2px rgba(gray, 0.2);
  --card-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --card-background: #f5f5f5;
  --card-background-rgb: 245, 245, 245;
  --card-title-background: #f9f9f9;
  --theme-accent: #f1bd6a;
  --play-button-color: linear-gradient(
    180deg,
    #273dbb,
    #273dbb 50%,
    #003dff 0,
    #003dff
  );
  --theme-accent-rgb: 241, 189, 106;
  --theme-error: #d11d1d;
  --theme-error-rgb: 209, 29, 29;
  --vod-title-color: var(--theme-accent);
  --vod-subtitle-color: var(--text-tertiary-middle);
  --card-header-background: #be311a;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card {
  font-size: 0.8rem;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: var(--card-shadow);
  display: flex;
  flex-direction: column;
  line-height: 1rem;
}

.card-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  gap: 1.5rem;
  margin-bottom: 3rem;
  font-size: 0.8rem;
}

.card_header {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  border-bottom: 2px solid var(--text-tertiary-light);
}

.card_body {
  position: relative;
  padding: 1rem;
  flex-grow: 1;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.card_level {
  background: var(--card-header-background);
  color: var(--text-secondary);
}

.card_names {
  display: grid;
  grid-template-rows: 2fr 1fr 2fr;
  box-sizing: content-box;
}

.card_date {
  background: var(--card-header-background);
  color: var(--text-secondary);
  display: flex;
  flex-direction: column;
}

.card_status {
  position: absolute;
  z-index: 1;
  top: 1rem;
  left: 1rem;
  width: 1.5rem;
  height: 1.5rem;
}

.card-images {
  aspect-ratio: 2.2222222222;
  display: grid;
  grid-template-columns: 1fr 2rem 1fr;
  align-items: center;
  justify-content: center;
}

.card-images div:first-child {
  display: flex;
  justify-content: flex-end;
}

.card-images div {
  display: flex;
  justify-content: flex-start;
}

.card-images img {
  width: 60%;
}

.card_button {
  padding: 1rem;
  font-weight: 900;
  font-size: 1rem;
}

.watch-button:hover {
  color: var(--settings-watch-button-text-color);
}

.watch-button {
  background: var(--settings-watch-button-background);

  font-family: var(--settings-body-font-family);
  font-weight: 900;
  font-style: italic;
  text-align: center;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.card {
  background-color: #355e3b;
}

.home-box {
  position: absolute;
  z-index: 4; /* put .gold-box above .green-box and .dashed-box */
  background: #00387a;
  width: 62%;
  height: 100%;
  left: 38%;
  top: 0px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.5rem;
}

.divider {
  position: absolute;
  display: block;
  z-index: 5;
  width: 36%;
  height: 100%;
  transform-origin: bottom left;
  transform: skew(-15deg);
  background-color: #355e3b;
  left: 10px;
  top: 0;
}

.header-banner {
  position: absolute;
  z-index: 6; /* put .gold-box above .green-box and .dashed-box */
  background: #d3d3d3;
  width: 100%;
  height: 16%;
  left: 0px;
  top: 20px;
}

.channel-logo {
  position: absolute;
  z-index: 7; /* put .gold-box above .green-box and .dashed-box */
  height: 100%;
  left: 85%;
  top: 5%;
}

.channel-logo img {
  height: 90%;
}

.event-description {
  position: absolute;
  z-index: 7;
  top: 25%;
  left: 0px;
  font-weight: 500;
  font-size: 0.8rem;
  color: white;
  padding: 0.2rem 0rem 0rem 0.3rem;
}

.logo-box {
  position: absolute;
  z-index: 6; /* put .gold-box above .green-box and .dashed-box */
  background: white;
  width: 84%;
  height: 44%;
  left: 8%;
  top: 80px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.5rem;
}

.team-name {
  font-weight: 900;
  font-size: 0.9rem;
  color: var(--text-tertiary-dark);
  padding: 0.2rem 0rem 0rem 0.3rem;
}

.event-date {
  position: absolute;
  z-index: 7;
  top: 85%;
  left: 0px;
  font-weight: 500;
  font-size: 0.9rem;
  color: white;
  padding: 0.2rem 0rem 0rem 0.3rem;
}

.event-time {
  position: absolute;
  z-index: 7;
  top: 91%;
  left: 0px;
  font-weight: 500;
  font-size: 0.7rem;
  color: white;
  padding: 0.2rem 0rem 0rem 0.3rem;
}

.action-button {
  position: absolute;
  z-index: 7;
  top: 81%;
  left: 72%;
}

.w3-button {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.5rem;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
}
body,
html {
  color: #777;
  line-height: 1.8;
}

/* Create a Parallax Effect */
.bgimg-1,
.bgimg-2,
.bgimg-3 {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* First image (Logo. Full height) */
.bgimg-1 {
  background-image: url("https://sbc.bz/htptv/images/background_1.jpg");
  min-height: 100%;
}

/* Second image (Portfolio) */
.bgimg-2 {
  background-image: url("https://sbc.bz/htptv/images/background_1.jpg");
  min-height: 400px;
}

/* Third image (Contact) */
.bgimg-3 {
  background-image: url("https://sbc.bz/htptv/images/background_1.jpg");
  min-height: 400px;
}

.w3-wide {
  letter-spacing: 10px;
}
.w3-hover-opacity {
  cursor: pointer;
}

/* Turn off parallax scrolling for tablets and phones */
@media only screen and (max-device-width: 1600px) {
  .bgimg-1,
  .bgimg-2,
  .bgimg-3 {
    background-attachment: scroll;
    min-height: 400px;
  }
}

/*W3 customizations */
.w3-bar {
  background-color: #edf2f7;
}

.signup-form {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 5%;
  margin-bottom: 50px;
}

.signup-form-firstchild {
  width: 60%;
}

.signup-form-secondchild {
  width: 35%;
  padding: 75px 0px;
  display: flex;
  height: 100%;
}

/* New Design */

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,700;1,800&display=swap");
html,
body {
  font-family: "Roboto", sans-serif;
  background-color: #fff;
}

a:hover {
  text-decoration: none;
}

body {
  top: 5px !important;
}

.navbarCustom {
  background-color: #edf2f7 !important;
}

.navbarCustom .navbar-toggler {
  background-color: #fff;
}

.navbarCustom .navbar-nav .nav-item .nav-link {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #6d7278;
  font-weight: 400;
  letter-spacing: 1px;
}

.navbarCustom .navbar-nav .nav-item.active .nav-link,
.navbarCustom .navbar-nav .nav-item:hover .nav-link {
  color: #003876;
}

.navbarCustom .navbar-nav .dropdown .dropdown-menu {
  padding: 0;
  top: 59px;
  background-color: #edf2f7;
  border: 0;
  border-radius: 0;
}

.navbarCustom .navbar-nav .dropdown .dropdown-menu .dropdown-item {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: #6d7278;
  font-weight: 400;
  padding: 10px 20px;
}

.navbarCustom .navbar-nav .dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #6d727d;
  color: black;
}

.homeBanner {
  position: relative;
}

.homeBanner .content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  padding-top: 20%;
}

.homeBanner .content .contentCenter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
}

.homeBanner .content .contentCenter h1 {
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  color: #fff;
  font-weight: 500;
  text-align: center;
}

.homeBanner .content .contentCenter p {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  text-align: center;
}

.mainContnet {
  padding: 30px 0;
  padding-bottom: 235px;
  min-height: calc(100vh - 175px);
  position: relative;
}

.mainContnet p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #393939;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 20px;
}

.mainContnet ul.list {
  padding: 0;
  margin: 0;
}

.mainContnet ul.list li {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #393939;
  font-weight: 500;
  margin-bottom: 10px;
  list-style-type: circle;
  list-style-position: inside;
}

.mainContnet ol.numList {
  padding: 0;
  margin: 0;
}

.mainContnet ol.numList li {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #393939;
  font-weight: 500;
  margin-bottom: 10px;
  list-style-position: outside;
  padding-left: 10px;
  margin-left: 15px;
}

.mainContnet a {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #000000;
  font-weight: 500;
  text-decoration: underline;
}

.mainContnet a:hover {
  text-decoration: none;
}

.mainContnet .videoPart {
  border: solid 1px #ededed;
  padding: 20px;
}

.mainContnet .aboutImg {
  float: right;
  display: table;
  margin: 0.5em 0 0.5em 1em;
}

.mainContnet .book img {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 0 1px 1px 0;
  -webkit-box-shadow: 1px -1px 0 #dcdcdc, 3px -3px 0 #d1d1d1, 4px -4px 0 #999,
    2px -2px 0 #200f3c, 5px 5px 15px rgba(0, 0, 0, 0.3);
  box-shadow: 1px -1px 0 #dcdcdc, 3px -3px 0 #d1d1d1, 4px -4px 0 #999,
    2px -2px 0 #200f3c, 5px 5px 15px rgba(0, 0, 0, 0.3);
}

.mainContnet .book figcaption {
  font-size: 0.8em;
  opacity: 0.75;
  margin: 1em 0;
  text-align: center;
}

.mainContnet .btnCenter {
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.mainContnet .note {
  border: 2px solid #fcde65;
  border-radius: 4px;
  background-color: #fcf7e3;
  margin: 10px 0;
  padding: 4px 10px;
}

.btnPrimary {
  background-color: #6d7a8d;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #6d7a8d;
  font-weight: 500;
  border-radius: 30px;
  padding: 10px 20px;
  min-width: 100px;
  color: #6d7a8d !important;
  text-decoration: none !important;
}

.btn:hover {
  background-color: #003876 !important;
  color: black !important;
}

.btnSecondary {
  background-color: #edf2f7;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 500;
  border-radius: 30px;
  padding: 10px 20px;
  min-width: 100px;
  color: white !important;
  text-decoration: none !important;
}

.btnSecondary:hover {
  background-color: #003876 !important;
  color: black !important;
}

.btnWhite {
  background-color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #edf2f7;
  font-weight: 500;
  border-radius: 30px;
  padding: 10px 20px;
  min-width: 100px;
  border: solid 1px #edf2f7;
}

.btnWhite:hover {
  background-color: #edf2f7;
  border: solid 1px #edf2f7;
}

.btnBSuccess {
  background-color: #00aa00;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  border-radius: 30px;
  padding: 5px 10px;
  min-width: 80px;
  border: 0;
}

.btnBSuccess:hover {
  background-color: #00aa00;
}

.btnDanger {
  background-color: #cf0101;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  border-radius: 30px;
  padding: 5px 10px;
  min-width: 80px;
  border: 0;
}

.btnDanger:hover {
  background-color: #cf0101;
}

.bgWhite {
  background-color: #fff;
}

.bgPrimary {
  background-color: #003876;
}

.bgDark {
  background-color: #1e494f;
}

.py-80 {
  padding: 80px 0;
}

.icon svg path {
  fill: #000;
}

.mt120 {
  margin-top: 120px;
}

.mb80 {
  margin-bottom: 80px;
}

.mt50 {
  margin-top: 50px;
}

.mt30 {
  margin-top: 30px;
}

h1 {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  color: #edf2f7;
  font-weight: 500;
}

h2 {
  font-family: "Roboto", sans-serif;
  font-size: 26px;
  color: #edf2f7;
  font-weight: 500;
}

p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: 400;
}

.colorWhite {
  color: #fff;
}

.font18 {
  font-size: 18px;
  color: #edf2f7;
}

.latestSermonsBox {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  height: 100%;
}

.latestSermonsBox .title {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #000;
  font-weight: 700;
  margin: 20px 0 10px 0;
}

.latestSermonsBox p {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #000;
  font-weight: 400;
}

footer {
  padding: 3px 0;

  width: 100%;
  left: 0;
  bottom: 0;
}

footer p,
footer b {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: italic;
  color: #fff;
  font-weight: 300;
  margin-bottom: 10px;
  color: #fff !important;
}

.heroBanner {
  margin-top: 75px;
  padding: 50px 0;
}

.heroBanner h2 {
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 10px;
}

.heroBanner .videoFrame {
  background-color: #fff;
  padding: 15px;
  border-radius: 30px;
  -webkit-box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);
}

.coursePart {
  background-color: #1e494f;
  padding: 30px;
  border-radius: 10px;
}

.coursesBox {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  height: 100%;
}

.coursesBox .courseBoxImg {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: #fff;
  margin: 0 auto;
}

.coursesBox .courseBoxImg img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
}

.coursesBox .title {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #000;
  font-weight: 700;
  margin: 20px 0 10px 0;
}

.coursesBox p {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #000;
  font-weight: 400;
}

.list ul {
  padding: 0;
  margin: 0;
}

.list ul li {
  list-style-type: none;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
}

.list ul li span {
  width: 40px;
}

.list ul li span img {
  width: 24px;
}

.socialMediaBox {
  background-color: #fff;
  padding: 50px 15px;
  border-radius: 10px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.socialMediaBox .icon img {
  width: 100px;
}

.socialMediaBox .title {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #000;
  font-weight: 700;
  margin-top: 30px;
}

.socialMediaBox:hover {
  background-color: #edf2f7;
}

.socialMediaBox:hover .title {
  color: #fff;
  text-decoration: none !important;
}

.methodBG {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.imgFrame {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.loginMain {
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.loginMain .login .logo {
  margin-bottom: 10px;
}

.loginMain .login .loginBox {
  background-color: #edf2f7;
  padding: 15px;
  border-radius: 10px;
}

label {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #f5f5f5;
  font-weight: 400;
  margin-bottom: 5px;
  text-transform: none;
}

.fontBlk {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #000;
  font-weight: 400;
}

.input {
  background-color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  border-radius: 30px;
  border: solid 1px #edf2f7;
}

.input:focus {
  color: #000;
}

.linkWhite {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
}

.linkWhite:hover {
  color: #000000;
}

.linkSecondary {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #000000;
  font-weight: 500;
}

.linkSecondary:hover {
  color: #fff;
}

.step-indicator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.step {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.step-indicator .step-icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #c2c2c2;
  font-size: 10px;
  text-align: center;
  color: #ffffff;
  position: relative;
  line-height: 50px;
  font-size: 20px;
}

.step.active .step-icon {
  background: #edf2f7;
}

.step p {
  text-align: center;
  position: absolute;
  bottom: -40px;
  color: #c2c2c2;
  font-size: 14px;
  font-weight: bold;
}

.step.active p {
  color: #edf2f7;
}

.step.step2 p,
.step.step3 p {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.indicator-line {
  width: 100%;
  height: 2px;
  background: #c2c2c2;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.indicator-line.active {
  background: #edf2f7;
}

@media screen and (max-width: 500px) {
  .step p {
    font-size: 11px;
    bottom: -20px;
  }
}

.stepDetails {
  margin: 50px 0;
}

.stepDetails h2 {
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  color: #edf2f7;
  font-weight: 700;
}

.stepDetails h3 {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  margin-bottom: 20px;
}

.stepDetails .videoPart {
  background-color: #f5f5f5;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.stepDetails .questionPart {
  border: solid 1px #f5f5f5;
  padding: 20px;
}

.stepDetails hr {
  border-top: solid 2px #f5f5f5;
}

.stepDetails .radio {
  margin-top: 10px;
}

.stepDetails .radio input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.stepDetails .radio input[type="radio"] + .radio-label:before {
  content: "";
  background: #fff;
  border-radius: 100%;
  border: 1px solid #bfbfbf;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: 0;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
}

.stepDetails .radio input[type="radio"]:checked + .radio-label:before {
  background-color: #edf2f7;
  -webkit-box-shadow: inset 0 0 0 4px #fff;
  box-shadow: inset 0 0 0 4px #fff;
}

.stepDetails .radio input[type="radio"]:focus + .radio-label:before {
  outline: none;
  border-color: #edf2f7;
}

.stepDetails .radio input[type="radio"]:disabled + .radio-label:before {
  -webkit-box-shadow: inset 0 0 0 4px #fff;
  box-shadow: inset 0 0 0 4px #fff;
  border-color: #bfbfbf;
  background: #bfbfbf;
}

.stepDetails .radio input[type="radio"] + .radio-label:empty:before {
  margin-right: 0;
}

.stepDetails .radio label {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #edf2f7;
  font-weight: 500;
}

.linkDark {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #edf2f7;
  font-weight: 700;
}

.linkDark:hover {
  color: #000000;
}

.recentEvemtPart {
  border-bottom: solid 1px #f5f5f5;
}

.recentEvemtPart .datepart .month {
  background-color: #edf2f7;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.recentEvemtPart .datepart .date {
  font-family: "Roboto", sans-serif;
  font-size: 50px;
  color: #1e494f;
  font-weight: 700;
  text-align: center;
}

.recentEvemtPart .detailspart h3 {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  color: #000;
  font-weight: 700;
}

.sidebar {
  background-color: #f5f5f5;
  width: 300px;
  height: calc(100vh - 75px);
  position: fixed;
  left: 0;
  top: 75px;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  z-index: 99;
}

.sidebar ul {
  padding: 0;
  margin: 10px 0 0 0;
  width: 100%;
}

.sidebar ul li {
  margin-bottom: 10px;
  list-style-type: none;
}

.sidebar ul li a {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  padding: 10px 20px;
  display: block;
}

.sidebar ul li a:hover,
.sidebar ul li a.active {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  text-decoration: none;
  background-color: #edf2f7;
  border-radius: 30px;
}

.nodata {
  width: 100%;
  padding: 20px;
  background-color: #f5f5f5;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  text-align: center;
}

.profile-pic {
  color: transparent;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  transition: all 0.3s ease;
}

.profile-pic input {
  display: none;
}

.profile-pic img {
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  width: 165px;
  height: 165px;
  -webkit-box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
  box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
  border-radius: 100px;
  z-index: 0;
}

.profile-pic .-label {
  cursor: pointer;
  height: 165px;
  width: 165px;
  margin-bottom: 0 !important;
}

.profile-pic:hover .-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000;
  color: #fafafa;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
  border-radius: 100px;
  margin-bottom: 0 !important;
}

.profile-pic span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0.2em;
  height: 2em;
}

.alertPart {
  background-color: #cf0101;
  margin-top: 75px;
  padding: 5px 0;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
}

@media (max-width: 992px) {
  .sidebar {
    width: 100%;
    position: fixed;
    top: 75px;
    border-radius: 0;
    height: auto;
    z-index: 99;
  }
  .sidebar .logo {
    display: none;
  }
  .sidebar ul {
    margin: 0;
  }
}

.navbarTogglerCustom {
  background-color: #edf2f7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.navbarTogglerCustom span {
  width: 20px;
  height: 2px;
  background-color: #edf2f7;
  margin: 4px 0;
}

.navbarTogglerCustom:hover {
  background-color: #edf2f7;
}

.navbarTogglerCustom:hover span {
  background-color: #000;
}

.mobileMenu {
  position: fixed;
  top: 75px;
  left: 0;
  width: 100%;
  background-color: #edf2f7;
  z-index: 99;
}

.mobileMenu .navbar-nav .nav-link {
  color: #fff;
  padding: 20px;
}

.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #000;
}

.userPic {
  width: 50px;
  height: 50px;
}

.userPic img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

.nav-item {
  margin-left: 10px;
}

.dropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  /* display: flex; */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.middlePartMain {
  width: 100%;
  padding-left: 300px;
  padding-top: 75px;
}

.middlePartMain .middle {
  padding: 30px;
}

.contactForm label {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #393939;
  font-weight: 300;
}

.contactForm .form-control {
  border: solid 1px #edf2f7;
}

@media (max-width: 992px) {
  .heroSlider h1 {
    font-size: 35px;
  }
  .heroSlider h2 {
    font-size: 24px;
  }
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 18px;
  }
  .heroBanner h2 {
    font-size: 24px;
  }
  .userButton {
    padding: 0;
    border: 0;
  }
  .middlePartMain {
    padding-left: 0;
  }
  .dropdown {
    display: unset;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .homeBanner .content {
    padding-top: 10%;
  }
  .mainContnet {
    min-height: calc(100vh - 166px);
  }
}

@media (max-width: 576px) {
  .audiorowPart .audioImg {
    width: 100%;
    margin-bottom: 10px;
  }
  .audiorowPart .audioDetails {
    width: 100%;
    padding-left: 0;
  }
  .step-indicator .step-icon {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
  }
  .step p {
    display: none;
  }
  .stepDetails {
    margin: 0;
  }
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}

.goog-te-gadget .goog-te-combo {
  color: rgb(0, 0, 0) !important;
  font-weight: 600;
}

#google_translate_element img {
  display: none !important;
}

.goog-te-banner-frame {
  display: none;
  height: 0 !important;
  visibility: hidden;
}

#google_translate_element {
  display: none;
}

#goog-gt-tt {
  color: #22222205;
  /* background-color: #ffffff; */
  border: 1px solid #eee;
  box-shadow: 0 4px 16px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 4px 16px rgb(0 0 0 / 20%);
  display: none !important;
  font-family: arial;
  font-size: 10pt;
  width: 420px;
  padding: 12px;
  position: absolute;
  z-index: 10000;
}

.goog-text-highlight {
  background-color: transparent !important;
  box-shadow: 0 0 0 0 transparent !important;
}
.main-content {
  background-color: var(--settings-body-background);
}

.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}
.h-custom {
  height: calc(100% - 73px);
}
@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}

.field-icon {
  float: right;
  margin-right: 5px;
  margin-top: -35px;
  position: relative;
  z-index: 2;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: none;
}

.field-icon {
  margin-top: -26px;
}

.login-btn {
  background-color: var(--settings-login-button-background);
  border-radius: 0.3rem;
  color: #fff;
  width: max-content;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.login-btn:hover {
  background-color: #000 !important;
  color: #fff !important;
  box-shadow: none !important;
}

.link {
  width: max-content;
  color: var(--theme-link-accent);
  font-weight: 900;
  font-size: 1.3rem;
  font-style: italic;
}

.button-colors > p {
  font-weight: 900;
  font-size: 1.3rem;
  font-style: italic;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.main-content {
  padding: 150px 0;
  background-color: var(--settings-body-background);
  height: 100% !important;
}

.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}
.h-custom {
  height: calc(100% - 73px);
}
@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}

.field-icon {
  float: right;
  margin-right: 5px;
  margin-top: -35px;
  position: relative;
  z-index: 2;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: none;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  font-style: italic;
  font-size: 2.6em;
  text-transform: uppercase;
  font-weight: 400;
  text-align: center;
}

.field-icon {
  margin-top: -26px;
}

.login-btn {
  background: black;
  cursor: default;
  border-radius: 0.3rem;
  color: var(--text-secondary);
  width: max-content;
  box-shadow: 0 0 0 rgba(var(--box-shadow-color-rgb), 0);
  font-style: italic;
  text-transform: uppercase;
  padding: 0.7rem 1rem !important;
  font-weight: 500;
}
.login-btn:hover {
  background: var(--text-tertiary-middle) !important;
  color: var(--text-secondary) !important;
  box-shadow: 3px 3px 6px 1px rgba(var(--box-shadow-color-rgb), 0) !important;
}
.link {
  width: max-content;
  color: var(--theme-link-accent);
  font-weight: 900;
  font-size: 1.2rem;
  font-style: italic;
}
.login-btn:disabled {
  background: var(--text-tertiary-middle) !important;
  color: var(--text-secondary) !important;
  box-shadow: 3px 3px 6px 1px rgba(var(--box-shadow-color-rgb), 0) !important;
}

.button-colors > p {
  font-weight: 900;
  font-size: 1.1rem;
  font-style: italic;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.button-colors > a {
  font-weight: 900;
  font-size: 1.1rem;
}

.term > p {
  margin-left: 1rem;
  font-family: var(--settings-body-font-family);
  font-weight: 900;
  font-style: italic;
  text-align: left;
  color: var(--text-primary);
  font-size: 1.2rem;
  line-height: 1.5rem;
}
.term > p > span {
  color: var(--theme-link-accent);
}
.slick-prev:before {
  content: "" !important;
}
.slick-next:before {
  content: "" !important;
}

.slick-dots li button:before {
  font-size: 20px !important;
}
.slick-dots {
  bottom: -40px !important;
}
.card-footer-button {
  background-color: white;
  border: none;
  border-radius: 4px;
  font-weight: 600;

  width: 100%;
  height: 30px;
  text-transform: uppercase;
  font-style: italic;
  font-size: 12px;
  padding: 0px 8px;
}
.manage-button-usah {
  background-color: #00387a; /* Green */
  color: white;
  border: none;
  padding: 8px 10px;
  display: inline-block;
  font-size: 12px;
  border-radius: 4px;
  font-style: italic;
  font-weight: 700;
  text-transform: uppercase;

  padding: "0px auto";
}
.manage-button-usah {
  background-color: #0055a5; /* Green */
  color: white;
  border: none;
  padding: 8px 10px;
  display: inline-block;
  font-size: 12px;
  border-radius: 4px;
  font-style: italic;
  font-weight: 700;
  text-transform: uppercase;

  padding: "0px auto";
}
.common-button-usah {
  background-color: #00387a; /* Green */
  color: white;
  border: none;
  padding: 10px 10px;
  text-transform: capitalize;
  width: 100%;
  text-transform: uppercase;
  display: inline-block;
  font-size: 18px;
  border-radius: 4px;
  font-style: italic;
  font-weight: 700;
}
.common-button-usah:disabled {
  background-color: grey;
}
.common-button-lvictv {
  background-color: #134ea5; /* Green */
  color: white;
  border: none;
  padding: 10px 10px;
  text-transform: capitalize;
  width: 100%;
  text-transform: uppercase;
  display: inline-block;
  font-size: 18px;
  border-radius: 4px;
  font-style: italic;
  font-weight: 700;
}
.common-button-lvictv:disabled {
  background-color: grey;
}
.common-button-golivesport {
  background-color: #273057; /* Green */
  color: white;
  border: none;
  padding: 10px 10px;
  text-transform: capitalize;
  width: 100%;
  text-transform: uppercase;
  display: inline-block;
  font-size: 18px;
  border-radius: 4px;
  font-style: italic;
  font-weight: 700;
}
.manage-button-golivesport {
  background-color: #273057; /* Green */
  color: white;
  border: none;
  padding: 8px 10px;
  display: inline-block;
  font-size: 12px;
  border-radius: 4px;
  font-style: italic;
  font-weight: 700;
  text-transform: uppercase;

  padding: "0px auto";
}

.common-button-golivesport:disabled {
  background-color: grey;
}
.bmpui-ui-watermark {
  display: none;
}
.common-button-ssm {
  background-color: #5b0816; /* Green */ /*Primary*/
  color: white;
  border: none;
  padding: 10px 10px;
  text-transform: capitalize;
  width: 100%;
  text-transform: uppercase;
  display: inline-block;
  font-size: 18px;
  border-radius: 4px;
  font-style: italic;
  font-weight: 700;
}
.common-button-ssm:disabled {
  background-color: grey;
}
.common-button-pjhl {
  background-color: #251d25; /* Green */ /*Primary*/
  color: white;
  border: none;
  padding: 10px 10px;
  text-transform: capitalize;
  width: 100%;
  text-transform: uppercase;
  display: inline-block;
  font-size: 18px;
  border-radius: 4px;
  font-style: italic;
  font-weight: 700;
}
.common-button-pjhl:disabled {
  background-color: grey;
}
.common-button-stansteadtv {
  background-color: #da291c; /* Green */ /*Primary*/
  color: white;
  border: none;
  padding: 10px 10px;
  text-transform: capitalize;
  width: 100%;
  text-transform: uppercase;
  display: inline-block;
  font-size: 18px;
  border-radius: 4px;
  font-style: italic;
  font-weight: 700;
}
.common-button-stansteadtv:disabled {
  background-color: grey;
}
.common-button-nahl {
  background-color: #0055a5; /* Green */
  color: white;
  border: none;
  padding: 10px 10px;
  text-transform: capitalize;
  width: 100%;
  text-transform: uppercase;
  display: inline-block;
  font-size: 18px;
  border-radius: 4px;
  font-style: italic;
  font-weight: 700;
}
.common-button-nahl:disabled {
  background-color: grey;
}
.common-button-lakeplacid {
  background-color: #1d3d6b; /* Green */
  color: white;
  border: none;
  padding: 10px 10px;
  text-transform: capitalize;
  width: 100%;
  text-transform: uppercase;
  display: inline-block;
  font-size: 18px;
  border-radius: 4px;
  font-style: italic;
  font-weight: 700;
}
.common-button-lakeplacid:disabled {
  background-color: grey;
}

.common-button-dexter {
  background-color: #9d2235; /* Green */
  color: white;
  border: none;
  padding: 10px 10px;
  text-transform: capitalize;
  width: 100%;
  text-transform: uppercase;
  display: inline-block;
  font-size: 18px;
  border-radius: 4px;
  font-style: italic;
  font-weight: 700;
}
.MuiDataGrid-virtualScroller + div {
  display: none;
}
.common-button-dexter:disabled {
  background-color: grey;
}

.statview-header-color {
  /* background-color: #0055a5 !important; */
}
button[variant="icon"]:hover {
  background-color: transparent !important;
}
button[variant="icon"]:active {
  background-color: transparent !important;
}
button[variant="icon"]:focus {
  background-color: transparent !important;
  box-shadow: none;
  border: none;
}
button[variant="icon"]:focus-visible {
  background-color: transparent !important;
}
button[variant="icon"]:active {
  background-color: transparent !important;
  box-shadow: none;
  border: none;
}
.rs-picker-daterange-calendar-group {
  min-width: 0px;
  height: auto;
}
@media (max-width: 576px) {
  .rs-picker-daterange-calendar-group {
    max-width: 300px;
    height: auto;
  }
  .rs-anim-fade.rs-anim-in {
    min-width: 320px;
  }
}
@media (max-width: 420px) {
  .rs-anim-fade.rs-anim-in {
    width: 320px;
    overflow-x: scroll;
  }
}
