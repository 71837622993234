.order-review_wrapper {
  --background-review-color: #fff;
  --placeholder-color: #a3acba;
  --border-color: #d5dbe1;
  --secondary-background: #003876;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
  color: var(--font-color);
  text-align: left;
}
.slim {
  white-space: nowrap;
}
.payment_grid .preview {
  grid-area: preview;
}
.order-review_header {
  background-color: var(--secondary-background);
  color: var(--background-review-color);
  font-size: 1.3rem;
  line-height: 1.45rem;
  padding: 1rem;
  text-align: center;
}
.order-review_body {
  padding: 2.5rem;
}
.order-review_title {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1.3rem;
  margin-bottom: 2.5rem;
}
.order-review_title .logo {
  border: 1px solid var(--secondary-background);
  border-radius: 5px;
  padding: 0.3rem 0.2rem 0.2rem;
  line-height: 1;
}
.order-review_title .price {
  justify-self: flex-end;
}
.price_grid {
  display: grid;
  grid-template-columns: 1fr -webkit-max-content;
  grid-template-columns: 1fr max-content;
  row-gap: 1.3rem;
  line-height: 1.2rem;
  margin-bottom: 2.9rem;
  margin-top: 3.1rem;
}
.policy {
  margin-top: 1.3rem;
}
.policy p {
  color: #757a80;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
}
.name-feild {
  padding-top: 10px !important ;
  padding-bottom: 10px !important ;
}
