.ccaption {
  width: 80%;
  max-width: 800px;
  flex-direction: column;
  transform: translateY(0);
  opacity: 1;
  z-index: 1;
  text-shadow: 4px 2px 8px rgba(var(--button-primary-background-rgb), 0.71);
}
.carousel-control-prev,
.carousel-control-next {
  width: 100px;
}
.carousel-control-next-icon-div,
.carousel-control-prev-icon-div {
  background-color: black;
  padding: 10px;
  display: flex;
  align-items: center;

  border-radius: 50%;
}
.carousel-content {
  text-align: start;
  display: flex;
  height: 100%;
  background-color: white;
  background: linear-gradient(to right, white 0%, white 60%, transparent 100%);
  width: 40%;
  padding: 20px;
  /* margin: auto; */
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.carousel-item {
  height: 100vh;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.container-fluid {
  max-width: 1440px;
}
.h2 {
  font-size: 4.25rem;
  letter-spacing: 3px;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-style: italic;
}

.button {
  display: inline-block;
  background-color: red; /* Green */
  border: none;
  color: white;
  padding: 6px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  border-radius: 4px;
  font-style: italic;
  font-weight: 700;
  margin-bottom: 0 !important;
  width: max-content;
}

.section-heading {
  color: #282b2e;
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 700;
  margin-left: 50px;
}
.price-desc p {
  font-style: italic;
  text-transform: uppercase;
  font-size: 0.89rem;
  font-weight: 800;
  color: black;
}

p {
  font-style: italic;
  text-transform: uppercase;
  font-size: 0.89rem;
  font-weight: 700;
  color: black;
}
.main-package-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
  gap: 2rem;
}
.slick-dots li button:before {
  opacity: 0.25;
}
.slick-dots li button:focus:before {
  opacity: 0.25 !important;
}
.slick-dots li.slick-active button:focus:before {
  opacity: 0.75 !important;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75 !important;
}

.main-container {
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.d-flex {
  display: flex !important;
}

.w-100 {
  width: 100% !important;
}

.pricing-title {
  height: 5rem;
  padding: 15px 0;
  background-color: white;
  border-radius: 3px 3px 0 0;
  text-shadow: 1px 1px 1px var(--text-secondary);
  border-bottom: 1px solid rgba(var(--button-primary-background-rgb), 0.05);
  overflow: hidden;
}

.pricing-title h3 {
  margin: 0;
  font-size: 27px;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--text-tertiary-dark);
  font-style: italic;
}
.middel-part {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.pricing-features {
  height: 500px;
  flex-grow: 1;
}

.carousel-item {
  height: 29vw;
  margin-top: 16px;
}

@media (max-width: 992px) {
  .h2 {
    font-size: 2rem;
  }
}
@media (max-width: 576px) {
  .h2 {
    font-size: 1.5rem;
  }
  .carousel-content {
    width: 60%;
  }
  .carousel-item {
    height: 29vw;
  }
  .section-heading {
    margin-left: 20px;
  }
}
@media (max-width: 374px) {
  .h2 {
    font-size: 1.2rem;
  }
}
.slick-dots li button:before {
  opacity: 0.25;
}
.slick-dots li button:focus:before {
  opacity: 0.25 !important;
}
.slick-dots li.slick-active button:focus:before {
  opacity: 0.75 !important;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75 !important;
}
.card-update {
  max-width: 300px;
  border-radius: 4px;
}

@media (max-width: 992px) {
  .carousel-content {
    width: 60%;
  }
}
@media (max-width: 576px) {
  .card-update {
    width: 100%;
  }
}

.slick-list {
  margin: 0 -15px;
}
.slick-slide > div {
  padding: 0 8px;
}
