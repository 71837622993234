.event-head {
  color: black;
  font-style: italic;
  font-weight: 400;
  font-size: 26px;
  padding: 30px;
  background-color: white;
  text-transform: uppercase;
}

.date-pickers {
  display: flex;
}

.page-title {
  font-size: 1.5rem;
  font-weight: 400;
  padding: 1.5rem 0;
  font-style: italic;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  background-color: var(--card-background);
  flex-grow: 1;
  color: #000;
}

.options-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 2rem;
  align-items: center;
  padding-bottom: 1rem;
}

.calendar-wrapper {
  position: relative;
}

.date-pickers {
  min-width: 300px;
  text-transform: none;
}
.date-pickers input {
  font-size: 1.1rem;
  padding-left: 4rem;
  line-height: 1.5;
  border-radius: 4px;
  width: 100%;
  border: 1px solid #959799;
  color: #2d3748;
  padding-left: 4rem;
  padding-right: 12px;
}

.icon-wrapper {
  position: absolute;
  z-index: 2;
  margin: 1px;
  width: 3rem;
  top: 1px;
  left: 1px;
  bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--theme-tertiary-background);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.icon-wrapper svg {
  width: 100%;
  height: 70%;
}

.filtered-list-wrapper {
  position: relative;
}

.filter-wrapper {
  position: relative;
}

.filter-input {
  width: 100%;
  font-size: 1.1rem;
  line-height: 1.5;
  border-radius: 4px;
  padding: 0.5rem 0.7rem;
  outline: none;
  border: 1px solid var(--text-tertiary-middle);
  font-style: italic;
}
.filtered-list {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  cursor: pointer;
  z-index: 100;
  width: 100%;
  max-width: 100%;
  overflow-y: auto;
  max-height: 20rem;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  background-color: var(--text-secondary);
  box-shadow: var(--theme-box);
}
.filter-element {
  padding: 0.5rem 0.7rem;
  display: flex;
  justify-content: flex-start;
  font-weight: 500;
  transition: all 0.09s linear;
  text-align: left;
  line-height: 1.1;
}

.search-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 3rem;
  height: 100%;
  z-index: 2;
  padding: 0.5rem;
  color: var(--text-tertiary-middle);
}

.category-filter {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 2rem;
  align-items: flex-start;
  padding-bottom: 1rem;
}
.select-wrapper {
  display: flex;
  border-radius: 0.3rem;
  border: 1px solid var(--text-tertiary-middle);
  align-items: center;
  justify-content: center;
  color: #000;
  font-weight: 900;
  font-style: italic;
  font-size: 1.1rem;
}
.datepicker {
  width: 100%;
}
.select-wrapper span {
  padding: 0.7rem 1rem;
  border-bottom-left-radius: 0.3rem;
  border-top-left-radius: 0.3rem;
  background: var(--text-tertiary-light);
  font-weight: 900;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 7px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    right 0.75rem center/8px 10px no-repeat;
  border: none;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: var(--settings-body-font-family), sans-serif;
}

.custom-select:focus-visible {
  border: none;
  outline: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.no-event {
  color: #495057;
  font-style: italic;
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
}

.card-wrapper[data-v-b40b6a48] {
  display: grid;

  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));

  gap: 1.5rem;

  margin-bottom: 3rem;
  font-size: 0.8rem;
}

@media (max-width: 540px) {
  .card-wrapper[data-v-b40b6a48] {
    grid-template-columns: 1fr;
  }
}
