.sliding-container {
  width: 100%;
  height: 50px; /* Adjust as needed */
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center; /* Center the text */
  align-items: center; /* Center vertically */
}

.sliding-text {
  white-space: nowrap;
  animation: slide 20s linear infinite;
}
.slider-container {
  width: 700px;
  overflow: hidden;
  position: relative;
  border: 1px solid #000; /* Optional: for visualization */
}

.slider-text {
  position: absolute;
  white-space: nowrap;
  transform: translateX(100%);
  animation: slideText 12s linear infinite;
}

.slider-text.paused {
  animation-play-state: paused;
}

@keyframes slideText {
  0% {
    transform: translateX(100%);
  }
  40%,
  60% {
    transform: translateX(calc(50% - 100px)); /* Center with 10 seconds pause */
  }
  100% {
    transform: translateX(-100%);
  }
}
