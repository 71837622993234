.main-content {
  min-height: 75vh;
  flex-grow: 1;
  background-color: var(--settings-body-background);
  color: var(--settings-body-text-color);
}

.padding-top {
  padding-top: 30px;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.fade {
  transition: opacity 0.15s linear;
}

.banner-text {
  font-size: 14px;
  text-transform: none;
  text-align: left;
  font-weight: 500;
}

.banner-text p {
  margin-bottom: 0.5rem;
  font-size: small;
  font-weight: 500;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.ccaption {
  position: absolute;
  width: 80%;
  max-width: 800px;
  flex-direction: column;
  transform: translateY(0);
  opacity: 1;
  z-index: 1;
  text-shadow: 4px 2px 8px rgba(var(--button-primary-background-rgb), 0.71);
}

.ccaption h2 {
  font-size: 4.25rem;
  letter-spacing: 3px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-style: italic;
}

.button {
  background-color: red; /* Green */
  border: none;
  color: white;
  padding: 5px 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  border-radius: 4px;
  font-style: italic;
  font-weight: 700;
  margin-bottom: 6rem;
}

.hheading {
  color: black;
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 700;
  margin-left: 50px;
}
.main-container {
  max-width: 1540px;
  margin: 0 auto;
  width: 100%;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.d-flex {
  display: flex !important;
}

.w-100 {
  width: 100% !important;
}

.pricing-title {
  height: 5rem;
  padding: 15px 0;
  background-color: white;
  border-radius: 3px 3px 0 0;
  text-shadow: 1px 1px 1px var(--text-secondary);
  border-bottom: 1px solid rgba(var(--button-primary-background-rgb), 0.05);
  overflow: hidden;
}

.pricing-title h3 {
  margin: 0;
  font-size: 27px;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--text-tertiary-dark);
  font-style: italic;
}

.pricing-features {
  height: 500px;
  flex-grow: 1;
}

.price-desc {
  padding: 1.4rem;
  flex-grow: 1;
  text-align: left;
  color: gray;
}

.price-desc p {
  font-style: italic;
  text-transform: uppercase;
  font-size: 0.89rem;
  font-weight: 800;
  color: black;
}

h3 {
  color: #555555;
  font-weight: bold;
}
