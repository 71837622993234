.main-content {
  min-height: 75vh;
  flex-grow: 1;
  background-color: var(--settings-body-background);
  color: var(--settings-body-text-color);
}

.container {
  max-width: 1140px;
}

.padding-top {
  padding-top: 30px;
}

.account_head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5em;
  align-items: center;
}

.form-group label {
  font-size: 0.8125rem;
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #555;
  margin-bottom: 10px;
  cursor: pointer;
}

.form-group .value {
  font-size: 1rem;
  font-weight: 900;
  font-style: italic;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #000;
  margin-bottom: 10px;
  cursor: pointer;
}

.linked-button {
  background: var(--settings-common-button-background);
  color: var(--settings-common-button-text-color);
  font-weight: 600;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.linked-button:hover {
  color: var(--settings-common-button-text-color) !important;
}
.logout-btn {
  font-style: italic;
}
