.main-container-watch_header {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-container-watch_header span {
  color: white;
  padding-right: 2px;
}

.header__icon {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 90px;
  height: 110px;
}

.iframe-container {
  width: 100%;
  padding: 0 0 5px 5px;
}

.streams-container {
  width: 100%;
  display: flex;

  flex-wrap: wrap;
}

.main-container-watch #videos {
  height: 100%;
  display: flex;
  background: transparent;
  flex-wrap: wrap;
}

.main-container-watch #videos .video {
  width: 50% !important;
  height: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-container-watch #videos .video div {
  height: 100%;
  width: 60%;
}

.main-container-watch #videos .video .participant .name {
  height: 10%;
  z-index: 1;
  position: absolute;
  top: 0px;
  bottom: 0px;
  display: flex;
  color: #fff;
  left: 0px;
  justify-content: center;
  z-index: 10;
  background-color: transparent;
  width: 100%;
  text-shadow: 2px 2px 2px #000000;
}

#videos .participant video {
  width: 100%;
  height: 100%;
}

#videos .local .participant video {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

#videos .local .participant video::-webkit-media-controls-panel {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

.home-button {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 25px;
  height: 25px;
  /* background: url('../assets/left-arrow.svg') no-repeat; */
  background-size: 25px;
  cursor: pointer;
}

.main-container-watch #videos > div {
  display: flex;
  /* flex-basis: calc(50% - 40px);   */
  justify-content: center;
  flex-direction: column;
}

#videos .participant video::-webkit-media-controls-timeline,
#videos .participant video::-webkit-media-controls-play-button,
#videos .participant video::-webkit-media-controls-fullscreen-button,
#videos
  .participant
  video::-webkit-media-controls-toggle-closed-captions-button,
#videos .participant video::-webkit-media-controls-current-time-display {
  display: none;
}
#videos .participant video::-webkit-media-controls-panel {
  /* transform: rotateY(180deg);
  -webkit-transform:rotateY(180deg);
  -moz-transform:rotateY(180deg);  */
  padding-right: 40%;
  background: transparent !important;
}

video:focus {
  outline: none;
}
video:hover {
  background: none !important;
  box-shadow: none !important;
  text-shadow: none !important;
}
.footer {
  bottom: 0;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.toggleAudio,
.toggleVideo,
.screenSharingOn,
.screenSharingOff {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border: none;
  outline: none !important;
  z-index: 5;
}

.toggleAudio {
  position: absolute;
  bottom: 10px;
  left: 30px;
}

.toggleVideo {
  /* margin: 0 15px; */
  position: absolute;
  bottom: 10px;
  right: 30px;
}

.size-normal {
  font-size: 1.4em !important;
  line-height: 0.75em !important;
  vertical-align: -15%;
}

.unmuted {
  border-radius: 25px;
  background: #009688;
}
.muted {
  border-radius: 25px;
  background: #009688;
}
.videoOn {
  background: #9e9e9e;
  border-radius: 25px;
}
.videoOff {
  background: #9e9e9e;
  border-radius: 25px;
}
.screenSharingOn {
  background: #337ab7;
  border-radius: 25px;
}
.screenSharingOff {
  background: #dc3545;
  border-radius: 25px;
}

.participant {
  position: relative;
  height: 150px;
}

.navigate-back {
  display: flex;
  color: white;
  position: absolute;
  left: 35px;
  top: 15px;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.navigate-back-icon {
  display: flex;
  height: 25px;
  width: 25px;

  background-size: contain;
}
