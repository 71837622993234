.heder-card {
  margin-top: 16px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ececec;
}

.heder-card p {
  line-height: 16px;
  font-size: 1rem;
  font-style: italic;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

.subtitle {
  font-size: 10px;

  padding: 0px 4px;
}
.subtitle p {
  margin: 4px 0px;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  text-transform: uppercase;
  color: white;
}

.teamName {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 16px 20px;
  border-radius: 4px;
  width: 94%;
  margin: auto;
  height: 120px;
}

.footer-card {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  margin: 4px 0px;
}

.footer-card p {
  margin: 0;
  font-style: italic;
  font-weight: 700;
  text-transform: uppercase;
}

.teamLogo {
  /* height: 100px; */
  object-fit: contain;
}

.cardBody {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 0px 10px;
  border-radius: 4px;
  width: 100%;
  margin: auto;
  flex-grow: 1;
  height: max-content;
}

.card-list-team-log {
  object-fit: contain;
  width: 90px;
}

@media (max-width: 768px) {
  .card-list-team-log {
    width: 50px;
  }
}

@media (max-width: 575px) {
  .cardBody {
    height: max-content;
    flex-wrap: wrap;
    gap: 6px;
  }
  .card-list-team-log {
    width: 50px;
  }
}
